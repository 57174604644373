.table-sm {
  border-radius: 15px;
  overflow: hidden;
  background-color: var(--bs-table-border-color);
  border: 1px solid var(--bs-table-border-color);
}

.table-sm thead th {
  background-color: #343a40;
  color: #fff;
  border-top: none;
}

.table-sm th,
.table-sm td {
  font-size: 0.875rem;
  padding: 0.3rem;
  text-align: center;
  vertical-align: middle;
}

.table-sm tr:first-child th:first-child {
  border-top-left-radius: 15px;
}

.table-sm tr:first-child th:last-child {
  border-top-right-radius: 15px;
}

.table-sm tr:last-child td:first-child {
  border-bottom-left-radius: 15px;
}

.table-sm tr:last-child td:last-child {
  border-bottom-right-radius: 15px;
}

.rounded-table {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
}
