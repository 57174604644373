@import "./variables.css";

.money {
  text-align: right !important;
}

.default-container {
  max-width: 90%;
  margin: 0 auto;
}

th {
  vertical-align: middle;
}

.dark-table th {
  background-color: #343a40;
  color: #fff;
  border-top: none;
}

.hotroom-yellow-button {
  background-color: var(--hotroom-yellow);
  border-color: var(--hotroom-yellow);
  color: black;
}

.hotroom-yellow-button:disabled {
  background-color: var(--hotroom-yellow);
  border-color: var(--hotroom-yellow);
  color: black;
}

.hotroom-yellow-button:hover {
  background-color: var(--hotroom-yellow-hover);
  border-color: var(--hotroom-yellow-hover);
  color: black;
}

.hotroom-yellow-button:active {
  background-color: var(--hotroom-yellow-active) !important;
  border-color: var(--hotroom-yellow-active) !important;
  color: black !important;
}

.label-font {
  font-weight: 600;
}
