.rdtPicker {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtDay.rdtActive,
.rdtPicker td.rdtMonth.rdtActive,
.rdtPicker td.rdtYear.rdtActive {
  background-color: #007bff;
  color: #fff;
  border-radius: 0.25rem;
}

.rdtPicker td.rdtToday::before {
  border-bottom: 7px solid #007bff;
}

.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #d6d8db;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}
