.table-responsive {
  margin-top: 20px;
}

.thead-dark th {
  background-color: #343a40;
  color: #fff;
}

.table-sm th,
.table-sm td {
  font-size: 0.875rem;
  padding: 0.3rem;
  text-align: center;
}

.wider-table {
  width: 100%;
}

.filters {
  margin-bottom: 20px;
}

.filters-card {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
html {
  overflow-y: scroll;
}

body.modal-open {
  overflow-y: hidden;
  padding-right: 0 !important;
}

.filters .accordion-button,
.filter .accordion-button:focus,
.filter .accordion-button:active {
  background-color: var(--hotroom-yellow);
  border-color: var(--hotroom-yellow);
  font-weight: bold;
  box-shadow: none;
  outline: none;
}
